/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Typography,
} from "antd";

import {
  LogoutOutlined
} from "@ant-design/icons";

import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";


function Header({
  name,
  subName,
}) {
  const { Text } = Typography;
  const { user } = useAuthContext()
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb style={{ textTransform: "capitalize" }} items={[{title: <NavLink to="/home">Dashboard</NavLink>}, {title: name.replace("/", "")}]}>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button className="p-5 mx-5">
            <LogoutOutlined 
              style={{
                verticalAlign: 'middle',
                alignItems: 'center'
              }}/>
          </Button>
          <Text className="subtitle">{user}</Text>                   
        </Col>
      </Row>
    </>
  );
}

export default Header;
