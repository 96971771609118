/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { Layout } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import {MenuFoldOutlined,   MenuUnfoldOutlined} from '@ant-design/icons';
const { Header: AntHeader, Sider } = Layout;

const Main = ({ children }) => {
  const [sidenavColor] = useState("#1890ff");
  const [sidenavType] = useState("white");
  const [collapsed, setCollapsed] = useState(false);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  return (
    <Layout
      className="layout-dashboard"
    >
      <AntHeader className="ant-header-fixed">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => setCollapsed(!collapsed),
        })}              
        <Header className="ant-header-fixed"
          name={pathname}
          subName={pathname}
        />
      </AntHeader>
      <Layout>     
        <Sider
          breakpoint="lg"
          collapsible 
          trigger={null}
          collapsedWidth={0}
          collapsed={collapsed}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>      
          <Outlet>{children}</Outlet>   
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Main;
