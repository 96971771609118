import { useEffect, useState } from 'react';
import { formatDateYMD, formatDateYMDHM } from '../utils/dateFormats';
import { Card, Col, Row, Input, Button } from 'antd';
import useApi from '../data/api';
import { useAuthContext } from '../contexts/AuthContext';
const Results = () => {
  const [loading, setLoading] = useState(false);
  const [offerDate, setOfferDate] = useState(formatDateYMD(new Date(new Date().setDate(new Date().getDate()-2))))
  const [data, setData] = useState([])
  const [currentEdit, setCurrentEdit] = useState("")
  const { user } = useAuthContext()

  const initialEditState = {
      "awayScore": 0,
      "homeScore": 0,
      "winnerId": "",
      "dateAdd": "",
      "addedBy": "Operator"
    }

  const period1 = {
          "awayScore": 0,
          "homeScore": 0,
          "number": 1,
          "type": 0
  }

  const period2 = {
    "awayScore": 0,
    "homeScore": 0,
    "number": 2,
    "type": 0
}

  const [editableFields, setEditableFields] = useState(initialEditState)
  const [firstPeriod, setFirstPeriod] = useState(period1)
  const [secondPeriod, setSecondPeriod] = useState(period2)
  const { fetchApi } = useApi()

  const editable = (eventId) => {
    return currentEdit == eventId ? true : false
  }

  const editEvent = (eventId) => {
    if (currentEdit == eventId) {
      saveData(eventId)
    }
    if (currentEdit !== eventId) {
      setCurrentEdit(eventId) 
      }
  }

  const saveData = async (eventId) => {
      const newData = data.map(obj => {
        if (obj.fixtureId === eventId) {
          return {...obj, results: {
            homeScore: editableFields.homeScore,
            awayScore: editableFields.awayScore,
            winnerId: editableFields.winnerId,
            dateAdd: formatDateYMDHM(new Date()),
            addedBy: user,
            periods: [
              firstPeriod,
              secondPeriod
            ]
          }};
        }
        return obj;
      })
      let dataFound = newData.filter(event => event.fixtureId == eventId)[0]
      setData(newData)
      let response = await fetchApi({route: 'insert-results', method: 'POST', body: JSON.stringify(dataFound)})
      if (response == 'OK') {
        cancelEdit()
        fetchData()
      }
  }

  const cancelEdit = () => {
    setCurrentEdit("")
    setEditableFields(initialEditState)
    setFirstPeriod(period1)
    setSecondPeriod(period2)
  }
  const fetchData = async () => {
    let today = new Date()
    let thisDate = new Date(offerDate)
    if (Math.round((today - thisDate) / (1000 * 60 * 60 * 24)) > 7) {
      alert('Date must be within one week back relative to today')
      return
    }
    setLoading(true);
    cancelEdit()
    let response = await fetchApi({route: 'load-not-processed', method: 'POST', body:  JSON.stringify({
      "date": offerDate
    })})
    if (response !== {}) {
      setData(response);
      setLoading(false);
    }
    else {
      response = []
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <label>Predictions Date</label>
    <Input 
      value={offerDate}
      onChange={(e) => setOfferDate(e.target.value)}
      onPressEnter={fetchData}
      className='width-fit'
    />
    <button className='width-fit' onClick={fetchData}>SEARCH</button>
  {
    loading ? (
      <span>Loading...</span>
    ) :
    data.length !== 0 ?
    ( 
  
      <>
        { data.map((event) => (
          <Row gutter={[24, 0]} key={event.fixtureId}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card size="small" bordered={true} className="criclebox h-full">
            <div className="ant-list-box table-responsive padding-0 margin-0">          
            <table className="width-100 margin-auto">
              <thead>
                <tr style={{padding: '0px'}}>
                  <th colSpan="7"><p style={{width: '100%', float: 'left'}}><span>
                    <Button onClick={() => editEvent(event.fixtureId)}>{editable(event.fixtureId) ? 'SAVE' : 'EDIT'}</Button>
                    { editable(event.fixtureId) ? ( <Button onClick={() => cancelEdit()}>CANCEL</Button> ) : '' } </span>
                    <span>{formatDateYMDHM(event.date)} </span>
                    <span style={{marginLeft: '2%', color: 'black'}}>{event.name}</span>
                    </p></th>
                </tr>
                </thead>
              { editable(event.fixtureId) ? ( <tbody>
              <tr>
                  <th colSpan="3">Result Info</th>
                  <th colSpan="2">Period 1</th>
                  <th colSpan="2">Period 2</th>
                </tr>
              <tr>
                  <td>
                  Home Score
                  </td>
                  <td>
                  Away Score
                  </td>
                  <td>
                    Date added
                  </td>
                  <td>
                  Home Score    
                  </td>
                  <td>                        
                  Away Score
                  </td> 
                  <td>
                  Home Score          
                  </td>
                  <td>                        
                  Away Score 
                  </td>                     
                </tr>   
                <tr>
                  <td>
                  <Input
                          type="number"
                          defaultValue={event.results.homeScore}
                          value={editableFields.homeScore}
                          onChange={(e) => setEditableFields({ ...editableFields, homeScore: e.target.value})}
                          style={{
                            marginBottom: 8,
                            display: 'block',
                          }}
                        />
                  </td>
                  <td>
                  <Input
                          type="number"
                          defaultValue={event.results.awayScore}
                          value={editableFields.awayScore}
                          onChange={(e) => setEditableFields({ ...editableFields, awayScore: e.target.value})}

                          style={{
                            marginBottom: 8,
                            display: 'block',
                          }}
                        />
                  </td>
                  <td>{event.results.dateAdd}</td>
                  <td>
                  <Input
                        type="number"
                        defaultValue={event.results.periods[0].homeScore}
                        value={firstPeriod.homeScore}
                        onChange={(e) => setFirstPeriod({ ...firstPeriod, homeScore: e.target.value})}
                        style={{
                          marginBottom: 8,
                          display: 'block',
                        }}
                      />            
                  </td>
                  <td>                        
                    <Input
                        type="number"
                        defaultValue={event.results.periods[0].awayScore}
                        value={firstPeriod.awayScore}
                        onChange={(e) => setFirstPeriod({ ...firstPeriod, awayScore: e.target.value})}
                        style={{
                          marginBottom: 8,
                          display: 'block',
                        }}
                      />   
                  </td> 
                  <td>
                    <Input
                          type="number"
                          defaultValue={event.results.periods[1].homeScore}
                          value={secondPeriod.homeScore}
                          onChange={(e) => setSecondPeriod({ ...secondPeriod, homeScore: e.target.value})}
                          style={{
                            marginBottom: 8,
                            display: 'block',
                          }}
                        />            
                  </td>
                  <td>                        
                    <Input
                        type="number"
                        defaultValue={event.results.periods[1].awayScore}
                        value={secondPeriod.awayScore}
                        onChange={(e) => setSecondPeriod({ ...secondPeriod, awayScore: e.target.value})}
                        style={{
                          marginBottom: 8,
                          display: 'block',
                        }}
                      />   
                  </td>                     
                </tr>            
              </tbody> ) : (
                <tbody></tbody>
              )
              }
          </table>
          </div>
              </Card>
            </Col>
          </Row>  
        ))}
      </>
    ) : (
      <span>No data available</span>
    )
  }
  </>);
}

export default Results