import { useEffect, useState } from 'react';
import { formatDateYMD, formatDateYMDHM } from '../utils/dateFormats';
import { Card, Col, Row, Radio, Select } from 'antd';
import {
  HeartOutlined
} from '@ant-design/icons';
import { Typography } from 'antd';
import useApi from '../data/api';
const { Title } = Typography;
const Predictions = () => {
  const [loading, setLoading] = useState(false);
  const [processingDate, setProcessingDate] = useState(formatDateYMD(new Date()))
  const [offerDate, setOfferDate] = useState(formatDateYMD(new Date()))
  const [data, setData] = useState([])
  const { fetchApi } = useApi()
  const processingDateOptions = [
    {
      key: 'processingDateOpt1',
      label: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-3))),
      value: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-3))),
    },
    {
      key: 'processingDateOpt2',
      label: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-2))),
      value: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-2))),
    },
    {
      key: 'processingDateOpt3',
      label: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-1))),
      value: formatDateYMD(new Date(new Date().setDate(new Date().getDate()-1))),
    },
    {
      key: 'processingDateOpt4',
      label: formatDateYMD(new Date()),
      value: formatDateYMD(new Date()),
    }           
  ];

  const offerDateOptions = [
    {
      key: 'offerDateOpt1',
      label: formatDateYMD(new Date(processingDate)),
      value: formatDateYMD(new Date(processingDate))
    },
    {
      key: 'offerDateOpt2',
      label: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+1))),
      value: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+1))),
    },
    {
      key: 'offerDateOpt3',
      label: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+2))),
      value: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+2))),
    },
    {
      key: 'offerDateOpt4',
      label: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+3))),
      value: formatDateYMD(new Date(new Date().setDate(new Date(processingDate).getDate()+3))),
    },        
  ];

  const onChangePD = ({ target: { value } }) => {
    setProcessingDate(value);
    setOfferDate(value);
  };

  const onChangeOD = (value) => {
    setOfferDate(value);
  };

  const fetchData = async () => {
    setLoading(true);
    let response = await fetchApi({route: 'load-predictions', method: 'POST', body:  JSON.stringify({
      "date": processingDate, 
      "offer_date": offerDate
    })})
      setData(response);
      setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [processingDate, offerDate]);

  return (
    <>
    <label>Processing Date</label>
    <Radio.Group
    key="processingDate"
    options={processingDateOptions}
    onChange={onChangePD}
    value={processingDate}
    optionType="button"
    buttonStyle="solid"
    /> 
    <label>Offer Date</label>
    <Select
      style={{ width: 120 }}
      onChange={onChangeOD}
      options={offerDateOptions}
      value={offerDate}
    />
  {
    loading ? (
      <span>Loading...</span>
    ) :
    data.length !== 0 ?
    ( 
  
      <>
        { data.map((event) => (
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card size="small" bordered={true} className="criclebox h-full">
            <div className="ant-list-box table-responsive padding-0 margin-0">          
            <table className="width-100 margin-auto" key={event.eventId}>
              <thead>
                <tr style={{padding: '0px'}}>
                  <th colSpan="3"><p><span style={{width: '35%', float: 'left'}}>{formatDateYMDHM(event.eventDate)} </span><span style={{color: 'black', width: '65%', textAlign: 'center', float: 'right'}}>{event.categoryName} - {event.leagueName}</span></p></th>
                  <th colSpan="4">
                  <p><span style={{color: 'black', float:'left'}}>{event.competitor1Name} vs. {event.competitor2Name}</span></p>
                  </th>
                </tr>
                <tr>
                { event.markets.map((market) => (
                market.outcomes.sort((a,b) => a.outcomeId - b.outcomeId).map((outcome) => 
                <th style={{width: "14.285%", borderBottom: '1px solid goldenrod', textAlign: "center"}} key={event.eventId + '-market-' + market.marketId + '-' + outcome.outcomeId}>
                  <Title level={5} style={{color: 'goldenrod', fontWeight: 'bold'}}>{outcome.outcomeName}</Title>
                </th>
                )
                )
                )}
                { event.predictions.map((prediction) => (
                prediction.outcomes.sort((a,b) => a.outcomeId - b.outcomeId).map((outcome) => 
                <th style={{width: "14.285%", borderBottom: '1px solid goldenrod', textAlign: "center"}} key={event.eventId + '-prediction-' + prediction.marketId + '-' + outcome.outcomeName}>
                  <Title level={5} style={{color: 'goldenrod', fontWeight: 'bold'}}>{outcome.outcomeName}</Title>
                </th>
                )
                )
                )}                
                </tr>
                </thead>
              <tbody>
                <tr>
                  { event.markets.map((market) => (
                  market.outcomes.sort((a,b) => a.outcomeId - b.outcomeId).map((outcome) => 
                  <td style={{width: "14.285%", textAlign: "center"}} key={event.eventId + '-market-' + market.marketId + '-' + outcome.outcomeId}>
                    <Title level={5}>{parseFloat(outcome.value).toFixed(2)} </Title>
                  </td>
                  )
                  )
                  )}
                  { event.predictions.map((prediction) => (
                  prediction.outcomes.sort((a,b) => a.outcomeId - b.outcomeId).map((outcome) => 
                  <td style={{width: "14.285%", textAlign: "center"}} key={event.eventId + '-prediction-' + prediction.marketId + '-' + outcome.outcomeId}>
                    {outcome.bestPick ?
                    <Title level={5} style={{ color: 'green'}}><HeartOutlined style={{backgroundColor: 'green', color: 'white', borderRadius: '50%', padding: '5px'}} /> {parseFloat(outcome.trustScore).toFixed(2)}</Title> :
                    <Title level={5}>{parseFloat(outcome.trustScore).toFixed(2)}</Title>
                    }
                  </td>
                  )
                  )
                  )}                
                </tr>                  
              </tbody>
          </table>
          </div>
              </Card>
            </Col>
          </Row>  
        ))}
      </>
    ) : (
      <span>No data available</span>
    )
  }
  </>);
}

export default Predictions