import { createContext, useContext, useState } from 'react'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState('')
    const [user, setUser] = useState('')
    const logout = () => {
        setUser('')
        setAuthToken('')
    }
    return (
        <AuthContext.Provider
            value={{ 
                authToken, setAuthToken,
                user, setUser, logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext);