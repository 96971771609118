/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
/* import Tables from "./pages/Tables"; */
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import { useAuthContext } from "./contexts/AuthContext";
import Results from "./pages/Results";
import Predictions from "./pages/Predictions";
import Sports from "./pages/Sports";
import Categories from "./pages/Categories";
import Tournaments from "./pages/Tournaments";
import Competitors from "./pages/Competitors";
import 'antd/dist/reset.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authToken } = useAuthContext();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    if (authToken === '') navigate('/sign-in');
    if (location.pathname === '/') navigate('/sign-in');
    if (location.pathname === '') navigate('/sign-in');
  }, []);
  
    return (
      <div className="App">
        <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            <Route element={<Main />}>
              <Route path="/home" element={<Home/>}>
              </Route>              
{/*               <Route path="/offer" element={<Tables />}>
              </Route> */}
              <Route path="/predictions" element={<Predictions />}>
              </Route>
              <Route path="/results" element={<Results />}>
              </Route>
              <Route path="/sports" element={<Sports />}>
              </Route>
              <Route path="/categories" element={<Categories />}>
              </Route>
              <Route path="/tournaments" element={<Tournaments />}>
              </Route>
              <Route path="/competitors" element={<Competitors />}>
              </Route>
            </Route>         
          </Routes>
      </div>
    );
}

export default App;
