import { Button, Form, Input, Popconfirm, Table, Space } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import useApi from '../data/api';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([])
  const [message, setMessage] = useState('')
  const [edited, setEdited] = useState([])
  const [deleted, setDeleted] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { fetchApi } = useApi();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <>
        {text ? text.toString() : ''}</>
      ) : (
        text
      ),
  });
  const fetchData = async () => {
    setLoading(true);
    let response = await fetchApi({route: 'load-categories', method: 'GET', body: {} })
      setDataSource(response);
      setLoading(false);
  };

  const defaultColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '10%',
      ...getColumnSearchProps('id')
    },
    {
      title: 'SortOrder',
      key: 'sortNo',
      dataIndex: 'sortNo',
      width: '5%'
    },    
    {
      title: 'SportId',
      key: 'sportId',
      dataIndex: 'sportId',
      width: '10%',
      ...getColumnSearchProps('sportId'),
    },    
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'DisplayName',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '20%',
      editable: true,
      ...getColumnSearchProps('displayName'),
    },
    {
      title: 'Filters',
      dataIndex: 'filters',
      key: 'filters',
      width: '20%',
      editable: true,
      ...getColumnSearchProps('filters'),
    },
    {
      title: 'Operations',
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete({id: record.id, sportId: record.sportId})}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id && row.sportId === item.sportId);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    const newEdited = [...edited]
    const e_index = newEdited.findIndex((item) => row.id === item.id && row.sportId === item.sportId);
    let e_item = {}
    if (e_index > -1) {
      e_item = newEdited[e_index]
      newEdited.splice(e_index, 1, {
        ...e_item,
        ...row,
      });
    }  else {
      newEdited.push(row)
    } 
    setEdited(newEdited);
    setDataSource(newData);
  };
  const handleDelete = ({ id, sportId }) => {
    const deletedItemIndex = dataSource.findIndex((item) => item.id === id && item.sportId === sportId);
    const newData = [...dataSource]
    newData.splice(deletedItemIndex, 1)
    const deletedItem = dataSource.filter((item) => item.id === id && item.sportId === sportId)[0]
    const newDeleted = [...deleted]
    newDeleted.push(deletedItem)
    setDeleted(newDeleted)
    setDataSource(newData);
  };
  const saveToBD = async() => {
    if (edited.length > 0 || deleted.length > 0) {
      let response = await fetchApi({route: 'update-categories', method: 'POST', body: JSON.stringify({
        edited,
        deleted
      })})
        if (response === 'OK') {
          setMessage('Saved to database')
          setTimeout(() => {
            setMessage('')
          }, 3000);
        }
    } 
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
 
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
  {
    loading ? (
      <span>Loading...</span>
    ) :
    dataSource.length !== 0 ?
    ( 
        <div>
          <Space>
          <Button
            onClick={saveToBD}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            SAVE
          </Button>
          <p>{message}</p>
          </Space>          
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columns}
            pagination={{
              position: ['topCenter'],
              pageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: false
            }}
            sticky={true}
            scroll={{
              x: 'max-content'
            }}
                             
          />
        </div>
    ) : (
      <span>No data available</span>
    )
  }
  </>);
}

export default Categories