import apiPath from "../settings/apiSettings";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";

export default function useApi () {
    const { authToken, setAuthToken, setUser } = useAuthContext()
    const navigate = useNavigate();
    const logout = () => {
        setUser('')
        setAuthToken('')
    }
    const fetchApi = async ({route, method, body}) => {
        if(route !== '') {
        const requestOptions = {
            method: method,
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-type': 'application/json',
            },
            }
            if (method == 'POST') {
                requestOptions.body = body
            }
            const response = await fetch(apiPath+route, requestOptions)
            const res = await response.json();
            if (res.error == 'Unauthorized') {
                logout()
                navigate('/sign-in');
                return
            }
            if (res.error) {
                console.log(res.error)
                return
            }
            else {
                return res.data
            }
        }
    }
    return { fetchApi }
}
